import React, { useState } from "react";
import calculateSleepTimes from "./CalculateSleepTimes";
import Footer from "../../common/Footer/Footer";
import Sleep from "./BlogForAll.jsx/sleep";

function SleepCalculator() {
  const [wakeUpTime, setWakeUpTime] = useState("");
  const [sleepDuration, setSleepDuration] = useState(8);
  const [recommendedTimes, setRecommendedTimes] = useState([]);

  const handleCalculate = () => {
    const times = calculateSleepTimes(wakeUpTime, sleepDuration);
    setRecommendedTimes(times);
  };

  return (
    <>
      <div className="container mx-auto p-4 flex gap-6 mt-4">
        <div className="w-1/2">
          <h1 className="text-4xl font-bold mb-6">Sleep Calculator</h1>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <label className="block text-gray-800 font-semibold mb-2">
                Wake Up Time:
              </label>
              <input
                type="time"
                className="px-4 py-2 w-full border rounded"
                value={wakeUpTime}
                onChange={(e) => setWakeUpTime(e.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <label className="block text-gray-800 font-semibold mb-2">
                Sleep Duration (hours):
              </label>
              <input
                type="number"
                className="px-4 py-2 w-full border rounded"
                value={sleepDuration}
                onChange={(e) => setSleepDuration(e.target.value)}
                min="1"
                max="24"
              />
            </div>
          </div>
          <button
            className="mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600"
            onClick={handleCalculate}
          >
            Calculate
          </button>
          {recommendedTimes.length > 0 && (
            <div className="mt-6">
              <h2 className="text-2xl font-bold mb-4">
                Recommended Sleep Times:
              </h2>
              <ul className="list-disc pl-8">
                {recommendedTimes.map((time, index) => (
                  <li key={index} className="text-gray-800 mb-2">
                    {time}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="w-1/2">
          <h1>
            Sleep is a critical pillar of health. The quality of your sleep
            impacts core metabolic functions like immunity, cardiovascular
            health, and cognition. Use the sleep calculator to develop an
            optimal sleep schedule based on your unique biology and lifestyle
            preferences, including your age and either your bedtime or wake-up
            time. The sleep calculator is simple to use and ensures your
            schedule allows ample time for rest.{" "}
          </h1>
        </div>
      </div>
      <Sleep></Sleep>
      <Footer />
    </>
  );
}

export default SleepCalculator;
