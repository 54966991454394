import React, { useState } from "react";
import Pregnancy from "./BlogForAll.jsx/Pregnancy";
import Footer from "../../common/Footer/Footer";

function PregnancyDueDateCalculator() {
  const [selectedDate, setSelectedDate] = useState(null);

  const calculateDueDate = (dateString) => {
    if (dateString) {
      const selectedDate = new Date(dateString);
      const dueDate = new Date(selectedDate.getTime());
      dueDate.setDate(dueDate.getDate() + 280); // 40 weeks * 7
      return dueDate.toISOString().split("T")[0]; // format as yyyy-mm-dd
    }
    return "";
  };

  const dueDate = calculateDueDate(selectedDate);

  return (
    <>
      <div>
        <div className="container mx-auto p-4 bg-white shadow-md mt-10 rounded">
          <h1 className="text-2xl font-bold mb-4 text-lime-600">
            Pregnancy Due Date Calculator
          </h1>

          <label className="block my-2 text-lime-500 ">
            <span>First Day of Last Menstrual Period:</span>
            <input
              type="date"
              value={selectedDate || ""}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="ml-2 w-full border-lime-500 px-2 py-3.5 bg-lime-200"
            />
          </label>

          <div className="p-4 text-center font-bold text-lime-600">
            <h2 className="text-xl">Estimated Due Date: {dueDate}</h2>
          </div>
        </div>
      </div>
      <Pregnancy />
      <Footer />
    </>
  );
}

export default PregnancyDueDateCalculator;
