const calculateSleepTimes = (wakeUpTime, sleepDuration) => {
  const recommendedTimes = [];
  const wakeUpDate = new Date(`2000-01-01T${wakeUpTime}`);
  const sleepDurationInMs = sleepDuration * 60 * 60 * 1000;

  // Calculate sleep times for 5 sleep cycles
  for (let i = 1; i <= 5; i++) {
    const sleepTime = new Date(wakeUpDate - i * sleepDurationInMs);
    const hours = String(sleepTime.getHours()).padStart(2, "0");
    const minutes = String(sleepTime.getMinutes()).padStart(2, "0");
    recommendedTimes.push(`${hours}:${minutes}`);
  }

  return recommendedTimes;
};

export default calculateSleepTimes;
