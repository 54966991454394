import React from "react";

const Baccal = () => {
  return (
    <div>
      <div class="container mx-auto">
        <main class="mt-10">
          <div class="mb-4 md:mb-0 w-full mx-auto relative">
            <div class="px-4 lg:px-0">
              <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                এলকোহল থেকে মৃত্যু। রক্তে অ্যালকোহল কন্টেন্ট। এলকোহল বিষক্রিয়া
              </h2>
            </div>

            <img
              src="https://i23.delachieve.com/image/c91c53547e7d0ed6.jpg"
              class="w-full object-cover lg:rounded"
            />
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <p class="pb-6">
                এলকোহল থেকে মৃত্যু বিভিন্ন কারণের জন্য ঘটতে পারে: শিল্প এলকোহল
                ধারণকারী পানীয়ের ব্যবহার; হার্ট অ্যাটাক; পৌনঃপুনিক তীব্র
                প্যানক্রিয়েটাইটিস; রেনাল অপ্রতুলতা। বিলম্বিত কিন্তু প্রাণঘাতী
                রক্তনালীতে রক্ত জমাট বাঁধা। তারা দীর্ঘমেয়াদী এলকোহল অপব্যবহার
                বা ধূমপানের ফলে প্রদর্শিত হবে। কারণ এই প্রক্রিয়ার, রক্ত
                thickens এবং রক্তনালী দেয়ালে প্লেটলেট অ্যাগ্রিগেশন ঘটে।
                ফলস্বরূপ, জমাট বেঁধে গঠন করে। যখন তাদের এক, একটি স্ট্রোক ঘটে।
              </p>
              <h1 className="text-3xl font-semibold">
                এলকোহল বিষক্রিয়া লক্ষণ
              </h1>
              <p class="pb-6">
                যখন উপরে থ্রেশহোল্ড রক্ত এলকোহল নেশা আসে। বিষ ভদকা অভ্যন্তরীণ ও
                বহিস্থিত প্রদর্শন একটি বহুবচন হয়েছে। একজন ব্যক্তির মুখে লাল,
                বর্ধিত ঘাম হয়, চোখ চকমক করা শুরু, এবং আমরা রয়েছে - বিভ্রান্ত
                এবং হতাশ। বিবৃতি তাদের দৃঢ়তা, স্বন পরিবর্তন হারান। একজন ব্যক্তি
                তাদের আন্দোলন নিয়ন্ত্রণ করতে ceases, তারা জোচ্চোর এবং rougher
                হয়ে। বিষাক্ত ইথানল দ্বারা প্রভাবিত হয়ে মস্তিষ্ক ভিন্নভাবে
                perceives কি কাছাকাছি ঘটছে। নেশা উচ্চতর পর্যায়ে মাথা ঘোরা শুরু
                হয়ে গেলে বাকি দিকে (মিথ্যা বা বসা)। বিষ ছিল যেন সেজদা,
                পর্যাপ্তরূপে পরিস্থিতি মূল্যায়ন মতানুযায়ী। বমি বমি ভাব এবং বমি
                প্রদর্শিত হবে। এভাবে শরীর থেকে বের বিষ। তাহলে বিষক্রিয়া একটি
                শক্তিশালী ভদকা হয়, নাড়ি ড্রপ, শ্বাস-প্রশ্বাস গতি নিচে, ঠাণ্ডা
                ঘাম দাঁড়িয়েছে সামগ্রিক তাপমাত্রা পড়ে। এসব লক্ষণ মানে শরীর
                নেশা সঙ্গে মানিয়ে নিতে অক্ষম। প্রাথমিক চিকিত্সা প্রদান আবশ্যক।
                বিষক্রিয়া মানুষের গত পর্যায়ে পড়ে একটি মদ্যপ কোমা। বিষ চেতনা
                হারায়। শ্বাস-প্রশ্বাস হয়ে সবিরাম, পর্যন্ত ঘটাতে প্রকাশমান,
                চামড়া নীলাভ হয়ে যায়। এই ক্ষেত্রে, যদি না সময়োপযোগী সহায়তা,
                মৃত্যুর এলকোহল থেকে ঘটতে পারে।
              </p>
              <h1 className="tex-3xl font-semibold">ইথানল বিপদ</h1>
              <p class="pb-6">
                রোগের আন্তর্জাতিক ক্লাসিফিকেশন দেখানো হয়েছে যে দীর্ঘস্থায়ী
                এলকোহল নেশা মদ্যপান করে দেখা দেয়। সবাই এলকোহল নির্ভরতা নিজস্ব
                কারণে হয়েছে। কিন্তু রোগ কেউ জন্য ওৎ পেতে পারেন। গুরুত্বহীন
                অবস্থা এবং বয়স: অ্যালকোহল সেবনের অভ্যাস সকল মানুষের জন্য একটি
                বড় বিপর্যয়। ইথানল বিষ স্নায়ু এজেন্ট বিবেচনা করা হয়। এটা তোলে
                কেন্দ্রীয় স্নায়ুতন্ত্রের প্রভাবিত করে, মানুষের আত্মা কাজ
                ব্যাহত। এই মৃত্যুর কারণ হতে পারে। এটি সহজে অনুমান করা কি এলকোহল
                প্রাণঘাতী ডোজ হতে হবে অসম্ভব। এই অনেক কারণের দ্বারা প্রভাবিত
                হয়: ধ্বংস এলকোহল পরিমাণ, স্বাস্থ্যের সাধারণ রাষ্ট্র, রোগ, বয়স
                এবং ওজন উপস্থিতিতে। অধিকন্তু, প্রত্যেক ব্যক্তি জীব তাদের নিজস্ব
                পৃথক বৈশিষ্ট্য আছে। কারো জন্য এলকোহল ক্ষুদ্রতম ডোজ খুব বিপজ্জনক
                হতে পারে, এবং অন্যের জন্য - এবং ভদকা 0.7 লিটার সীমা হবে না।
              </p>
              <h1 className="text-3xl font-semibold">
                অ্যালকোহলের মেটাবলিজম প্রভাবিত অন্যান্য উপাদান
              </h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                আপনার শরীর আপনার সিস্টেমে এলকোহল নিষ্কাশন করা হবে কিভাবে দ্রুত
                বা ধীরে ধীরে প্রভাবিত করতে পারে এমন অন্যান্য কারণ রয়েছে। কিছু
                কারণের মধ্যে রয়েছে: জেনেটিক সেক্স জাতিতত্ত্ব শারীরিক ফ্যাট
                কন্টেন্ট বয়স মদ্যপান বা পান করার আগে আপনাকে কতটুকু খেতে হবে?
                আপনি খাওয়া খাদ্য ফ্যাট কন্টেন্ট মেডিকেশন কত দ্রুত আপনি
                অ্যালকোহল ক্ষয়প্রাপ্ত
              </div>

              <p class="pb-6">
                এটা সহজভাবে বলার জন্য, যদি আপনি পানিতে পান করেন- পুরুষদের জন্য
                পাঁচটি বা তার বেশি পানীয়, একটি একক পানীয়ের সময় মহিলাদের জন্য
                চার-এটি আপনার সিস্টেমে সম্পূর্ণরূপে পরিষ্কার থেকে অ্যালকোহল জন্য
                অনেক ঘন্টা সময় নিতে পারে। আপনার সিস্টেমে পরের দিন সকালে এটি
                যথেষ্ট পরিমাণে এলকোহল হতে পারে যে আপনি প্রভাব অধীনে ড্রাইভিং
                জন্য একটি প্রস্রাব বা রক্ত ​​পরীক্ষা ব্যর্থ হতে পারে। কোনও
                অ্যালকোহল উপস্থিতি সনাক্ত করার জন্য ডিজাইন করা একটি পরীক্ষা পাস
                করার জন্য আপনার অবশ্যই একটি সমস্যা হবে।
              </p>

              <h2 class="text-2xl text-gray-800 font-semibold mb-4 mt-4">
                অটো ব্রিউয়ারি সিনড্রোম
              </h2>

              <p class="pb-6">
                গাট ফার্মেন্টেশন সিনড্রোম নামেও পরিচিত এই রোগ। অ্যালকোহল সমৃদ্ধ
                পানীয় না খাওয়া সত্ত্বেও এই রোগে আক্রান্তদের রক্তে স্বাভাবিকের
                থেকে অধিক মাত্রার অ্যালকোহল পাওয়া যায়। ফলে মাতাল হলে যেসব
                উপসর্গ দেখা যায়, ঠিক তেমন সমস্যার সৃষ্টি হতে পারে। এ কারণে
                সামাজিক ও পারিবারিকভাবে বিব্রতকর পরিস্থিতিতে পড়তে হয় তাদের,
                এমনকি আইনগত ঝামেলায় জড়িয়ে পড়ার সম্ভাবনাও থাকে।
              </p>

              <p class="pb-6">
                সর্বপ্রথম জাপানে ১৯৫০ সালের দিকে জাপানে অটো ব্রিউয়ারি সিনড্রোম
                বা এবিএস-এর কথা শোনা যায়। অন্যান্য দেশেও কালক্রমে আবিষ্কৃত হয়
                এই রোগ। এখন পর্যন্ত অন্তত ৫৮ জন রোগীর কথা বিভিন্ন বৈজ্ঞানিক
                জার্নালে পাওয়া গেছে, তবে জাপানীদের মধ্যেই গবেষকেরা এবিএসের
                ঝুঁকি বেশি বলে মনে করেন। বৈজ্ঞানিকদের একটি দল মনে করেন যে তাদের
                মধ্যে জেনেটিক কারণে লিভারে অ্যালকোহলের বিপাক ক্রিয়া বাধাগ্রস্ত
                হয়, যে কারণে রক্তে এর মাত্রা বেড়ে যেতে পারে। মনে রাখতে হবে,
                কেবল নেশাজাতীয় পানীয় নয়, ফার্মেন্টেশনের মাধ্যমে আমাদের শরীরেও
                বিভিন্ন খাদ্য থেকে অ্যালকোহল তৈরি হয়। কিন্তু লিভার সেগুলো ভেঙে
                ফেলে বলে আমরা কিছু টের পাই না।
              </p>

              <p class="pb-6">
                দুর্লভ এই রোগে আক্রান্ত হতে পারেন পুরুষ-নারী উভয়েই। অনেক
                ক্ষেত্রেই এর সঠিক ডায়াগনোসিস হয় না। কারণ এরা এমন সব লক্ষণ
                প্রকাশ করেন, যা দেখে মনে হয় রোগবালাই না, বরং নেশাজাতীয় পানীয়
                পান করেছেন তারা। তাদের মুখে গন্ধ হয়, আচার আচরণে দেখা দেয়
                অসংলগ্নতা। ক্লান্তির কারণে কাজকর্মে দেখা দেয় নানা সমস্যা, ফলে
                চলে যেতে পারে চাকরি। পারিবারিক আর সামাজিকভাবে নানাভাবে অপদস্থ
                হতে পারেন এই রোগে আক্রান্তরা। যদিও রোগীর সাথে কথা বললে জানা যায়
                তেমন কিছু করেননি তারা। এদের অনেকেই পূর্বে প্রচুর পরিমাণে চিনি আর
                শর্করাযুক্ত খাবার গ্রহণের কথা জানান। কেউ কেউ সুস্থ থাকতে
                খাওয়া-দাওয়াই বন্ধ করে দেন, ফলে উল্টো প্রয়োজনীয় পুষ্টি থেকে
                বঞ্চিত হয় শরীর।
              </p>

              <p class="pb-6">
                দুর্লভ এই রোগে আক্রান্ত হতে পারেন পুরুষ-নারী উভয়েই। অনেক
                ক্ষেত্রেই এর সঠিক ডায়াগনোসিস হয় না। কারণ এরা এমন সব লক্ষণ
                প্রকাশ করেন, যা দেখে মনে হয় রোগবালাই না, বরং নেশাজাতীয় পানীয়
                পান করেছেন তারা। তাদের মুখে গন্ধ হয়, আচার আচরণে দেখা দেয়
                অসংলগ্নতা। ক্লান্তির কারণে কাজকর্মে দেখা দেয় নানা সমস্যা, ফলে
                চলে যেতে পারে চাকরি। পারিবারিক আর সামাজিকভাবে নানাভাবে অপদস্থ
                হতে পারেন এই রোগে আক্রান্তরা। যদিও রোগীর সাথে কথা বললে জানা যায়
                তেমন কিছু করেননি তারা। এদের অনেকেই পূর্বে প্রচুর পরিমাণে চিনি আর
                শর্করাযুক্ত খাবার গ্রহণের কথা জানান। কেউ কেউ সুস্থ থাকতে
                খাওয়া-দাওয়াই বন্ধ করে দেন, ফলে উল্টো প্রয়োজনীয় পুষ্টি থেকে
                বঞ্চিত হয় শরীর।
              </p>
            </div>

            <div class="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div class="p-4 border-t border-b md:border md:rounded">
                <div class="flex py-2">
                  <img
                    src="https://scontent.fdac138-1.fna.fbcdn.net/v/t39.30808-6/345611092_3405322226450369_2387465423623327984_n.jpg?_nc_cat=110&cb=99be929b-3346023f&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeG35K8N6iJb6-YdyTuVuJEceAth3-Uuc_F4C2Hf5S5z8WrXw-8z5LurSWOW_LlNFpSTZBuS4s3G6k7bqB1_QITE&_nc_ohc=3cOWm0S7VOUAX-kAVhG&_nc_ht=scontent.fdac138-1.fna&oh=00_AfDH4h6A825TGb8bcgDa9cL5y_BgKZrQhuc94rA_BqxfPA&oe=64C71829"
                    class="h-10 w-10 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p class="font-semibold text-gray-700 text-sm">
                      {" "}
                      Mahadi Hasan{" "}
                    </p>
                    <p class="font-semibold text-gray-600 text-xs"> Editor </p>
                  </div>
                </div>
                <p class="text-gray-700 py-3">
                  Mahadi writes about health related blogs
                </p>
                <button class="px-2 py-1 text-gray-100 bg-green-700 flex w-full items-center justify-center rounded">
                  Follow
                  <i class="bx bx-user-plus ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Baccal;
