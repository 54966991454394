import React, { useState } from "react";
import Baccal from "./BlogForAll.jsx/Baccal";
import Footer from "../../common/Footer/Footer";

function BACCalculator() {
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("male");
  const [numberOfDrinks, setNumberOfDrinks] = useState("");
  const [hoursSinceFirstDrink, setHoursSinceFirstDrink] = useState("");
  const [bacResult, setBACResult] = useState(null);

  const calculateBAC = () => {
    const alcoholRatio = gender === "male" ? 0.68 : 0.55;
    const alcoholConsumed = numberOfDrinks * 14; // Assuming one drink contains 14 grams of alcohol
    const totalBodyWater = weight * alcoholRatio;
    const metabolismRate = 0.015;
    const elapsedTime = hoursSinceFirstDrink;

    const bac = alcoholConsumed / totalBodyWater - metabolismRate * elapsedTime;
    setBACResult(bac.toFixed(3));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateBAC();
  };

  return (
    <>
      <div className="container mx-auto p-4 bg-white shadow-md rounded-md mt-16">
        <h1 className="text-2xl font-bold mb-4 text-lime-600">
          Blood Alcohol Content Calculator
        </h1>

        <div className="flex gap-10">
          {" "}
          <form onSubmit={handleSubmit} className="w-1/2">
            <label className="block my-2 flex justify-between items-center">
              <span>Weight (in kg):</span>
              <input
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                className="ml-2 w-24 border-lime-500 bg-lime-200 rounded-sm p-2"
                required
              />
            </label>

            <label className="block my-2 flex justify-between items-center">
              <span>Gender:</span>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="ml-2 border-lime-500 bg-lime-200 rounded-sm p-2"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </label>

            <label className="block my-2 flex justify-between items-center">
              <span>Number of Drinks:</span>
              <input
                type="number"
                value={numberOfDrinks}
                onChange={(e) => setNumberOfDrinks(e.target.value)}
                className="ml-2 w-24 border-lime-500 bg-lime-200 rounded-sm p-2"
                required
              />
            </label>

            <label className="block my-2 flex justify-between items-center">
              <span>Hours Since First Drink:</span>
              <input
                type="number"
                value={hoursSinceFirstDrink}
                onChange={(e) => setHoursSinceFirstDrink(e.target.value)}
                className="ml-2 w-24 border-lime-500 bg-lime-200 rounded-sm p-2"
                required
              />
            </label>

            <button
              type="submit"
              className="px-4 py-2 bg-lime-500 text-white font-bold rounded-md mt-4"
            >
              Calculate BAC
            </button>
          </form>
          <h1 className="w-1/2">
            <span className="font-bold">
              {" "}
              ব্লাড অ্যালকোহল কন্টেন্ট ক্যালকুলেটর{" "}
            </span>
            - BAC হল আপনার রক্তের অ্যালকোহল কন্টেন্ট (BAC) গণনা করার জন্য খুব
            সহজেই কিছু মৌলিক তথ্য প্রবেশ করে BAC হল সেরা অ্যান্ড্রয়েড মোবাইল
            অ্যাপ্লিকেশন। এবং এটি একটি খুব মৌলিক UI দিয়ে আসে যাতে যে কেউ এটি
            সহজেই ব্যবহার করতে পারে। এই অ্যাপ্লিকেশনটি আপনার ইনপুট শরীরের ওজন,
            আপনার খাওয়া পানীয়ের সংখ্যা, একটি লিঙ্গ-নির্দিষ্ট বিপাকীয় ধ্রুবক,
            এবং একটি অতিবাহিত সময়কাল ব্যবহার করে আপনার আনুমানিক শতাংশ
            রক্ত-অ্যালকোহল ঘনত্বের একটি মেট্রিক গণনা করে।
          </h1>
        </div>
        {bacResult && (
          <div className="mt-4 p-4 text-center font-bold text-lime-600 bg-lime-100 rounded-md">
            <h2 className="text-xl">
              Blood Alcohol Content (BAC): {bacResult}
            </h2>
          </div>
        )}
      </div>
      <div>
        <Baccal></Baccal>
        <Footer></Footer>
      </div>
    </>
  );
}

export default BACCalculator;
