import React, { useState } from "react";
import Footer from "../../common/Footer/Footer";
import Heart from "./BlogForAll.jsx/Heart";

function HeartRateZoneCalculator() {
  const [age, setAge] = useState(20);
  const [restingHR, setRestingHR] = useState(60);

  const maxHR = 220 - age;
  const zones = {
    "Zone 1 (50-60%)": [0.5, 0.6],
    "Zone 2 (60-70%)": [0.6, 0.7],
    "Zone 3 (70-80%)": [0.7, 0.8],
    "Zone 4 (80-90%)": [0.8, 0.9],
    "Zone 5 (90-100%)": [0.9, 1.0],
  };

  return (
    <>
      {" "}
      <div className="container mx-auto py-6 px-4 bg-white shadow-md mt-20">
        <div className="flex gap-6">
          <div className="w-1/2">
            <h1 className="text-2xl font-bold mb-4 text-lime-600">
              Heart Rate Zone Calculator
            </h1>

            <label className="block my-2 text-lime-500">
              <span>Age:</span>
              <input
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="ml-2 w-24 border-lime-500 bg-lime-200"
              />
            </label>

            <label className="block my-2 text-lime-500">
              <span>Resting Heart Rate:</span>
              <input
                type="number"
                value={restingHR}
                onChange={(e) => setRestingHR(e.target.value)}
                className="ml-2 w-24 border-lime-500 bg-lime-200"
              />
            </label>

            <div className="py-4 px-2 bg-lime-500 text-center font-bold text-white rounded-md">
              <h2 className="text-xl mb-2">Heart Rate Zones</h2>
              {Object.entries(zones).map(([zone, [low, high]]) => (
                <div key={zone} className="mb-1">
                  <span>{zone}: </span>
                  <span>
                    {Math.round((maxHR - restingHR) * low + restingHR)} -{" "}
                  </span>
                  <span>
                    {Math.round((maxHR - restingHR) * high + restingHR)}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className=" w-1/2">
            <h1 className="text-xl font-semibold pb-3">Calculator Use</h1>
            <h1>
              This calculator will determine your maximum heart rate (MHR) and
              calculate your exercise target heart rate (THR) zones in beats per
              minute (bpm). Target heart rate (THR) zones can be calculated from
              your age, age and measured resting heart rate (RHR) or, measured
              maximum heart rate (MHR) and measured resting heart rate (RHR).
              These calculations are for adults older than 19 years old.
            </h1>
            <span className="pt-4 font-semibold">
              Max Heart Rate Equation MHR = 220 - Age Target Heart Rate Formula
              (Basic) THR = MHR * %Intensity Karvonen Formula THR = [(MHR - RHR)
              x %Intensity] + RHR
            </span>
          </div>
        </div>
      </div>
      <Heart></Heart>
      <Footer></Footer>
    </>
  );
}

export default HeartRateZoneCalculator;
