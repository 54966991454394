import React from "react";

const Heart = () => {
  return (
    <div>
      <div class="container mx-auto">
        <main class="mt-10">
          <div class="mb-4 md:mb-0 w-full mx-auto relative">
            <div class="px-4 lg:px-0">
              <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                ক্রিটিকাল পরিচর্যা হৃদস্পন্দন – কোনটা স্বাভাবিক?
              </h2>
            </div>

            <img
              src="https://images.tv9bangla.com/wp-content/uploads/2021/04/Untitled-design-15.jpg?w=1200"
              class="w-full object-cover lg:rounded"
            />
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <p class="pb-6">
                হৃদপিণ্ড মানবদেহের একটি গুরুত্বপূর্ণ অঙ্গ যা সারা শরীরে
                অক্সিজেনযুক্ত রক্ত সরবরাহ করে। এটি সংবহনতন্ত্রের মাধ্যমে রক্ত
                পাম্প করে। হৃদপিন্ডের পাম্পকেই মানুষের হৃদস্পন্দন বলা হয়।
                প্রাপ্তবয়স্কদের জন্য একটি সাধারণ হৃদস্পন্দন প্রতি মিনিটে 60-100
                বার হয়।
              </p>
              <h1 className="text-3xl font-semibold">
                হৃদস্পন্দন বলতে আপনি কী বোঝেন?
              </h1>
              <p class="pb-6">
                একটি সাধারণ হৃদস্পন্দন মানে হৃদপিণ্ড যথাযথভাবে কাজ করছে এবং
                শরীরের সমস্ত অংশে অক্সিজেন রক্ত এবং খনিজ পদার্থ সরবরাহ করছে।
                হৃদস্পন্দন একটি স্পন্দন দ্বারা পরীক্ষা করা হয়, যে আপনি আপনার
                তর্জনী এবং তৃতীয় আঙ্গুল আপনার কব্জিতে, ঘাড়ে ইত্যাদিতে রেখে
                অনুভব করতে পারেন। আপনাকে অবশ্যই এক মিনিটের জন্য স্পন্দন গুনতে
                হবে অথবা ১৫ সেকেন্ডের জন্য গুনে তাকে চার দিয়ে গুণ করে আপনার
                হৃদস্পন্দন বার করতে হবে। হৃদস্পন্দন মাপার সবচেয়ে সঠিক সময় হল
                ঘুম থেকে ওঠার পর।
              </p>
              <h1 className="tex-3xl font-semibold">
                হৃদস্পন্দন – কোনটি স্বাভাবিক নয়?
              </h1>
              <p class="pb-6">
                অস্বাভাবিক হৃদস্পন্দন তখন হয় যখন হৃদপিণ্ড হয় খুব জোরে অথবা খুব
                ধীরে স্পন্দিত হচ্ছে। এটি একটি নির্দিষ্ট ছন্দ ছাড়াই অনিয়মিতভাবে
                স্পন্দিত হতে পারে। এটিকে অ্যারিথমিয়াস বলা হয়। অস্বাভাবিক
                হৃদস্পন্দন এর কিছু সাধারন ধরন হলো: ট্যাকিকার্ডিয়া: একটি শারীরিক
                অবস্থা যেখানে আপনার হৃদস্পন্দন সাধারণের থেকে দ্রুত হয়। এটিকে
                আরো ভাগে ভাগ করা যায় যেমন সুপ্রাভেন্ট্রিকুলার ট্যাকিকার্ডিয়া,
                ভেন্ট্রিকুলার ট্যাকিকার্ডিয়া, এবং সাইনাস ট্যাকিকার্নেয়া।
                ব্রাডিকার্ডিয়া: শারীরিক অবস্থা যা সাধারণ থেকে ধীর হৃদস্পন্দনের
                বিকাশ করে। ট্যাকিব্রাডি সিনড্রোম ( অসুস্থ সাইনাস সিনড্রোম) খুব
                দ্রুত অথবা ধীর হৃদস্পন্দনের দিকে পরিচালিত করে। অলিন্দের
                ফিব্রিলেশন হল সবচেয়ে সাধারণ অনিয়মিত, সাধারণত দ্রুত হৃদ ছন্দ।
                সুপ্রাভেন্ট্রিকুলার ট্যাকিকার্ডিয়া (SVT) হলো ভীষণ দ্রুত
                হৃৎস্পন্দন। বেশিরভাগ SVT গুলি অলিন্দ এবং নিলয়ের একটি অথবা একটির
                বেশি বৈদ্যুতিক পথের কারণে হয়। অলিন্দের ফ্লাটার হলো সাধারণত একটি
                দ্রুত হৃদের ছন্দ যেখানে অলিন্দের স্পন্দন নিলয়ের তুলনায় অনেক
                দ্রুত স্পন্দিত হয়। এটি অলিন্দকে দ্রুত স্পন্দিত করতে পারে, কখনো
                প্রতি মিনিটে 300 টি স্পন্দন। হৃদ ব্লক হৃদের ওপর এবং নিচের
                পরিবাহী ব্যবস্থার বন্ধ হওয়ার অথবা দেরি হওয়ার কারণে এটি হতে
                পারে, যা হৃদ স্পন্দনকে ধীর করে তোলে। নিলয়ের ফাইব্রিলেশন: একটি
                অবস্থা যেখানে হৃদ স্পন্দন যেকোনো সময় বন্ধ হয়ে যেতে পারে, যার
                ফলে কার্ডিয়াক অ্যারেস্ট হতে পারে। অকাল সংকোচন : এটি হলো একটি
                অবস্থা যেখানে মনে হয় আপনার হৃদপিন্দ একটি স্পন্দন এড়িয়ে গেল।
              </p>
              <h1 className="text-3xl font-semibold">এর উপসর্গগুলো কী কী?</h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                যদি আপনার স্বাভাবিক হৃদস্পন্দন না থেকে থাকে, আপনি একটি, কয়েকটি
                অথবা সবকটি নিম্নলিখিত উপসর্গগুলির বিকাশ ঘটাতে পারেন। সেগুলি হলো
                : মাথা ঘোরা অনিয়মিত স্পন্দন ঘাম হওয়া বুকে ব্যাথা নিঃশ্বাসে
                কষ্ট ত্বক ফ্যাকাশে হয়ে যাওয়া
              </div>

              <p class="pb-6">
                যদি আপনার স্বাভাবিক হৃদস্পন্দন না থাকে তাহলে আপনাকে অবশ্যই
                ডাক্তার দেখাতে হবে। প্রতি মিনিটে 100 বারের বেশি অথবা 60 বারের কম
                হলে আপনাকে অবশ্যই চিকিৎসা সাহায্য নিতে হবে। যদি আপনি মাথা ঘোরা
                অনুভব করেন অথবা নিঃশ্বাসের কষ্ট অনুভব করেন, আপনাকে অবশ্যই দ্রুত
                ডাক্তারের সাথে দেখা করতে হবে।
              </p>

              <h2 class="text-2xl text-gray-800 font-semibold mb-4 mt-4">
                সাধারণ হৃদস্পন্দনকে প্রভাবিত করে এই কারণগুলি কী কী?
              </h2>

              <p class="pb-6">
                সাধারণ হৃদস্পন্দনকেকে প্রভাবিত করে তার বিভিন্ন কারণগুলির
                অন্তর্ভুক্ত হলো: আবহাওয়া – উচ্চ তাপমাত্রা এবং উচ্চ আদ্রতার
                স্তরে স্পন্দন কিছুটা বেশি হবে। হঠাৎ দাঁড়িয়ে পড়া– আপনি
                দাঁড়ানোর পর প্রথম কুড়ি সেকেন্ড অবধি হৃদস্পন্দন বেশি দেখাবে।
                আবেগ – চরম আবেগ যেমন খুশি, উদ্বেগ, চিন্তা এবং চাপ হৃদস্পন্দন
                বাড়িয়ে তুলতে পারে। ঔষধ – যদি আপনি বিটা-ব্লকার জাতীয় ঔষধ নেন
                তবে আপনার হৃদস্পন্দন ধীর হতে পারে। আপনি প্রচুর থাইরয়েডের ঔষধ
                নিলে আপনার স্বাভাবিক হৃদস্পন্দনের তুলনায় হৃদস্পন্দন কিছুটা
                বাড়তে পারে। এ সমস্ত আম্ফেটামাইনস এবং বেটা ব্লকার থাকা ওষুধের
                কিছু অনিয়মিত হৃদস্পন্দনের কারণ হয় । ক্যাফিন : কফি, চা এবং
                চকলেট থেকে আসা ক্যাফিন এবং নিকোটিনের কারণে সাধারণ হৃদস্পন্দন
                বাড়াতে দেখা গেছে।।
              </p>

              <p class="pb-6">
                একটি সাধারণ হৃদস্পন্দন বজায় রাখা খুবই প্রয়োজনীয়। এটি সুস্থ
                থেকে এবং স্বাস্থ্যকর খাবার খেয়ে অর্জন করা যেতে পারে। এটি
                প্রয়োজনীয় কারণ আপনার হৃদস্পন্দন স্বাভাবিক থাকলে আপনি সবচেয়ে
                বেশি সুবিধা পাবেন।
              </p>
            </div>

            <div class="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div class="p-4 border-t border-b md:border md:rounded">
                <div class="flex py-2">
                  <img
                    src="https://scontent.fdac138-1.fna.fbcdn.net/v/t39.30808-6/345611092_3405322226450369_2387465423623327984_n.jpg?_nc_cat=110&cb=99be929b-3346023f&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeG35K8N6iJb6-YdyTuVuJEceAth3-Uuc_F4C2Hf5S5z8WrXw-8z5LurSWOW_LlNFpSTZBuS4s3G6k7bqB1_QITE&_nc_ohc=3cOWm0S7VOUAX-kAVhG&_nc_ht=scontent.fdac138-1.fna&oh=00_AfDH4h6A825TGb8bcgDa9cL5y_BgKZrQhuc94rA_BqxfPA&oe=64C71829"
                    class="h-10 w-10 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p class="font-semibold text-gray-700 text-sm">
                      {" "}
                      Mahadi Hasan{" "}
                    </p>
                    <p class="font-semibold text-gray-600 text-xs"> Editor </p>
                  </div>
                </div>
                <p class="text-gray-700 py-3">
                  Mahadi writes about health related blogs
                </p>
                <button class="px-2 py-1 text-gray-100 bg-green-700 flex w-full items-center justify-center rounded">
                  Follow
                  <i class="bx bx-user-plus ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Heart;
