import React from "react";

const Sleep = () => {
  return (
    <div>
      <div class="container mx-auto">
        <main class="mt-10">
          <div class="mb-4 md:mb-0 w-full mx-auto relative">
            <div class="px-4 lg:px-0">
              <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                রাত জেগে থাকছেন? জানুন রাতে ঘুমানোর উপকারিতা
              </h2>
            </div>

            <img
              src="https://www.jugantor.com/assets/news_photos/2021/09/18/image-466580-1631987467.jpg"
              class="w-full object-cover lg:rounded mt-4"
            />
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <p class="pb-6">
                অনেকেই রাতে জেগে থাকেন বা অনেক দেরি করে ঘুমান। কিন্তু রাতে ভালো
                এবং পর্যাপ্ত ঘুম হওয়া আপনার স্বাস্থ্যের জন্য অবিশ্বাস্যভাবে
                গুরুত্বপূর্ণ। কারণ ঘুম আমাদের শরীরকে রিচার্জ হতে সাহায্য করে। আর
                পর্যাপ্ত পরিমাণে ঘুম আপনাকে সারাদিনের কাজের জন্য প্রস্তুত রাখে।
                আমরা যেমন স্বাস্থ্যকর খাবার খাওয়া এবং ব্যায়ামকে সুস্বাস্থ্যের
                জন্য গুরুত্বপূর্ণ মনে করি, ঠিক তেমনই গুরুত্বপূর্ণ হচ্ছে রাতে
                ঘুমানো। এটি আপনাকে পরের সারাদিনটা ফুরফুরে রাখতে পারে। বর্তমান
                সময়ে মানুষের ঘুমের মাত্রা এবং মান আগের চেয়ে অনেক কমে গেছে। আর এর
                পেছনে অন্যতম একটি কারণ হচ্ছে ডিজিটাল ডিভাইসের ব্যবহার বেড়ে
                যাওয়া। কিন্তু রাতে ভালো ঘুম আমাদের শরীরকে সুস্থ রাখতে এবং রোগ
                প্রতিরোধে সহায়তা করতে অত্যন্ত গুরুত্বপূর্ণ ভূমিকা পালন করে।
              </p>
              <h1 className="text-3xl font-semibold">
                ১. কম ক্যালরি গ্রহণে সহায়তা করে
              </h1>
              <p class="pb-6">
                রাতে একটি ভালো ঘুম হলে তা আমাদের কম ক্যালরি গ্রহণে সহায়তা করতে
                পারে। গবেষণায় দেখা গেছে যে, রাতে যাদের ভালো ঘুম হয় না বা কম ঘুম
                হয় তাদের ক্ষুধা বেশি থাকে এবং ক্যালরি খাওয়ার প্রবণতা বেশি দেখা
                যায়। এছাড়া রাতে ঘুমের অভাব হলে তা আমাদের ক্ষুধা হরমোনের দৈনন্দিন
                ওঠানামাকে ব্যাহত করে এবং ক্ষুধা নিয়ন্ত্রণে দুর্বলতা সৃষ্টি করতে
                পারে।
              </p>
              <h1 className="tex-3xl font-semibold">
                ২. কর্মক্ষমতা ও মস্তিস্কের কার্যকারিতা বাড়ায়
              </h1>
              <p class="pb-6">
                জ্ঞান, একাগ্রতা, উৎপাদনশীলতা এবং কর্মক্ষমতা নির্ভর করে
                মস্তিস্কের কার্যকারিতার ওপর। আর এর সবই ভালো ঘুমের মাধ্যমে
                ইতিবাচকভাবে প্রভাবিত হয়। একটি গবেষণায় দেখা গেছে যে, ঘুমের
                ঘাটতি মস্তিষ্কের কার্যকারিতার কিছু দিককে অ্যালকোহলের নেশার মতো
                প্রভাব ফেলতে পারে। এছাড়া আরেকটি গবেষণায় দেখা যায়, ভালো ঘুম
                সমস্যা সমাধানের দক্ষতা বৃদ্ধি করতে এবং স্মৃতিশক্তি উন্নত করতে
                সহায়তা করে।
              </p>
              <h1 className="text-3xl font-semibold">
                ৩. স্ট্রোকের ঝুঁকি কমায়
              </h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                ঘুমের মান ও সময়কাল স্বাস্থ্যের ওপর অনেক প্রভাব ফেলে। পর্যাপ্ত
                ঘুমের অভাবে রক্তচাপ এবং হার্টের কার্যকারিতা খারাপ হয়। এর ফলে
                স্ট্রোকের ঝুঁকি বেড়ে যেতে পারে। গবেষণায় দেখা গেছে যে, যারা রাতে
                পর্যাপ্ত ঘুমায় না তাদের হৃদরোগ বা স্ট্রোকের ঝুঁকি অনেক বেশি
                থাকে।
              </div>
              <h1 className="text-3xl font-semibold">
                ৪. ডায়াবেটিসের ঝুঁকি কমায়
              </h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                রাতে ভালো ঘুম হলে তা আপনার ডায়াবেটিসের ঝুঁকি কমাতে পারে। কারণ
                রাতে কম ঘুম হলে তা রক্তে শর্করাকে প্রভাবিত করে এবং ইনসুলিন
                সংবেদনশীলতা হ্রাস করে। এর ফলে ডায়াবেটিসের ঝুঁকি বাড়ে। যুবকদের
                একটি গবেষণায় দেখা গেছে যে, টানা ৬ রাত ৪ ঘণ্টা করে ঘুমানোর ফলে
                তাদের ডায়াবেটিসের উপসর্গ দেখা দিয়েছে।
              </div>
              <h1 className="text-3xl font-semibold">
                ৫. রোগ-প্রতিরোধ ক্ষমতা বৃদ্ধি করে
              </h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                পর্যাপ্ত পরিমাণে ঘুম আপনার রোগ-প্রতিরোধ ক্ষমতা বৃদ্ধিতে সহায়তা
                করে। আর ঘুমের ঘাটতি আপনাকে দুর্বল করে দিতে পারে। কিছু মানুষের
                ওপর গবেষণা করে দেখা গেছে যে, যারা কম ঘুমান তাদের অন্যদের তুলনায়
                সর্দি লাগার সম্ভাবনা প্রায় তিনগুণ বেশি থাকে।
              </div>
            </div>

            <div class="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div class="p-4 border-t border-b md:border md:rounded">
                <div class="flex py-2">
                  <img
                    src="https://scontent.fdac138-1.fna.fbcdn.net/v/t39.30808-6/345611092_3405322226450369_2387465423623327984_n.jpg?_nc_cat=110&cb=99be929b-3346023f&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeG35K8N6iJb6-YdyTuVuJEceAth3-Uuc_F4C2Hf5S5z8WrXw-8z5LurSWOW_LlNFpSTZBuS4s3G6k7bqB1_QITE&_nc_ohc=3cOWm0S7VOUAX-kAVhG&_nc_ht=scontent.fdac138-1.fna&oh=00_AfDH4h6A825TGb8bcgDa9cL5y_BgKZrQhuc94rA_BqxfPA&oe=64C71829"
                    class="h-10 w-10 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p class="font-semibold text-gray-700 text-sm">
                      {" "}
                      Mahadi Hasan{" "}
                    </p>
                    <p class="font-semibold text-gray-600 text-xs"> Editor </p>
                  </div>
                </div>
                <p class="text-gray-700 py-3">
                  Mahadi writes about health related blogs
                </p>
                <button class="px-2 py-1 text-gray-100 bg-green-700 flex w-full items-center justify-center rounded">
                  Follow
                  <i class="bx bx-user-plus ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Sleep;
