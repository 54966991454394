import React from "react";

const Pregnancy = () => {
  return (
    <div>
      <div class="container mx-auto">
        <main class="mt-10">
          <div class="mb-4 md:mb-0 w-full mx-auto relative">
            <div class="px-4 lg:px-0">
              <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
                প্রেগন্যান্সি টেস্ট করার সঠিক সময়
              </h2>
            </div>

            <img
              src="https://assets.shohay.health/uploads/2022/07/image-1-edited.png"
              class="w-full object-cover lg:rounded"
            />
          </div>

          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
              <p class="pb-6">
                মাসিকের সম্ভাব্য সময়ের মধ্যে মাসিক শুরু না হলে এবং সম্প্রতি আপনি
                জন্মনিরোধক পদ্ধতি (যেমন: কনডম, পিল বা বড়ি ও ইনজেকশন) ব্যবহার
                ছাড়া সহবাস করে থাকলে, প্রেগন্যান্সি টেস্ট করতে পারেন। মাসিক মিস
                হওয়ার প্রথম দিনই আপনি প্রেগন্যান্সি টেস্ট করে গর্ভবতী কি না সেটি
                জেনে নিতে পারেন। প্রেগন্যান্সি টেস্টে সাধারণত গর্ভবতী নারীদের
                প্রস্রাবে একটি হরমোনের উপস্থিতি নির্ণয় করা হয়। গর্ভবতী নারীদের
                প্রস্রাবে এই হরমোনের পরিমাণ শুরুর দিকে অল্প পরিমাণে থাকে। তাই
                গর্ভধারণের একদম শুরুর দিকে অথবা সহবাসের পর পরই এই টেস্ট করলে
                সাধারণত হরমোনের উপস্থিতি সঠিকভাবে নির্ণয় করা সম্ভব হয় না।
              </p>
              <h1 className="text-3xl font-semibold">
                কীভাবে প্রেগন্যান্সি টেস্ট করবেন?
              </h1>
              <p class="pb-6">
                প্রেগন্যান্সি টেস্ট করার অনেকগুলো উপায় আছে। এর মধ্যে
                প্রেগন্যান্সি টেস্ট কিট বা কাঠি দিয়ে আপনি সবচেয়ে সহজ ও কম খরচে
                ঘরে বসেই টেস্ট করতে পারবেন। সাধারণত নির্দিষ্ট সময়ে পিরিয়ড না হলে
                প্রথমেই প্রেগন্যান্সি কিটের সাহায্যে টেস্ট করা হয়ে থাকে।
                ফার্মেসিতে ৩০–১০০ টাকার মধ্যে বিভিন্ন কোম্পানির প্রেগন্যান্সি
                টেস্ট কিট পাওয়া যায়। এ ছাড়াও ডাক্তারের কাছে গিয়ে রক্ত পরীক্ষা,
                প্রস্রাব পরীক্ষা ও আলট্রাসাউন্ড পরীক্ষার মাধ্যমে আপনি গর্ভবতী কি
                না সেই বিষয়ে পুরোপুরি নিশ্চিত হতে পারবেন। পাশাপাশি গর্ভের
                সন্তানের হৃৎপিণ্ডের কার্যক্রমও হয়তো শনাক্ত করা যেতে পারে।
              </p>
              <h1 className="tex-3xl font-semibold">
                প্রেগন্যান্সি টেস্ট কিট ব্যবহারের নিয়ম
              </h1>
              <p class="pb-6">
                প্রস্তুতকারকভেদে প্রেগন্যান্সি টেস্ট কিটের ব্যবহারবিধি কিছুটা
                ভিন্ন হতে পারে। কিটের প্যাকেটের ভেতরের নির্দেশিকায় কীভাবে
                প্রেগন্যান্সি টেস্ট করতে হবে সেটি বিস্তারিতভাবে লেখা থাকে।
                নির্দেশিকা অনুসরণ করে আপনি সহজেই ঘরে বসে প্রেগন্যান্সি টেস্ট করে
                ফেলতে পারবেন। সচরাচর যেসব প্রেগন্যান্সি টেস্ট কিট পাওয়া যায়,
                সেগুলোর প্যাকেটের ভেতরে একটি লম্বা কাঠি বা বক্স থাকে। তাতে একটি
                ‘S’ লেখা ঘর থাকে। এ ঘরে আপনাকে কয়েক ফোঁটা প্রস্রাব দিয়ে কয়েক
                মিনিট অপেক্ষা করতে হবে। তারপর বক্সের ‘C’ ও ‘T’ লেখা অন্য দুইটি
                ঘরের দিকে লক্ষ রাখতে হবে। শুধু ‘C’ ঘরে একটি দাগ দেখা গেলে
                পরীক্ষার ফলাফল নেগেটিভ, অর্থাৎ আপনি হয়তো গর্ভবতী না। আর ‘C’ ও
                ‘T’ দুইটি ঘরেই দাগ দেখা গেলে ফলাফল পজিটিভ, অর্থাৎ আপনি গর্ভবতী।
              </p>
              <h1 className="text-3xl font-semibold">
                প্রেগন্যান্সি টেস্ট কিট কীভাবে কাজ করে?
              </h1>
              <div class="border-l-4 border-gray-500 pl-4 mb-6 italic rounded">
                প্রেগন্যান্সি টেস্ট কিটের মাধ্যমে প্রস্রাবে ‘বেটা হিউম্যান
                কোরিওনিক গোনাডোট্রপিন’ নামের একটি বিশেষ হরমোনের উপস্থিতি নির্ণয়
                করা হয়। গর্ভবতী নারীদের প্রস্রাবে এই হরমোনের পরিমাণ অনেক বেশি
                থাকে। যা কিটের মাধ্যমে শনাক্ত করা যায়। টেস্ট কিট দিয়ে প্রস্রাবে
                এই হরমোনের উপস্থিতি শনাক্ত করা গেলে সেই ফলাফলকে ‘পজিটিভ’ বলে। আর
                শনাক্ত করার মতো যথেষ্ট পরিমাণ হরমোন না পাওয়া গেলে তাকে ‘নেগেটিভ’
                বলে। ফলাফল পজিটিভ আসলে তা বেশিরভাগ ক্ষেত্রেই নির্ভুল হয়ে থাকে।
                তাই ফলাফল পজিটিভ আসলে একজন গাইনী ডাক্তার অথবা হাসপাতালে গিয়ে
                গর্ভাবস্থায় করণীয় সম্পর্কে জেনে নিতে হবে।
              </div>

              <p class="pb-6">
                জেনে রাখা ভালো প্রেগন্যান্সি টেস্টের ফলাফল পজিটিভ হওয়ার পরেও
                আপনি চিকিৎসকের কাছে যেয়ে আরও কিছু পরীক্ষা-নিরীক্ষা করে পুরোপুরি
                নিশ্চিত হয়ে নেবেন। পুরোপুরি নিশ্চিত হওয়ার জন্য আরও যেসব পরীক্ষা
                করা হয়— আল্ট্রাসনোগ্রাফি করা হয় ফিতা দিয়ে জরায়ুর আকার মাপা হয়
                রক্ত পরীক্ষা করা হয় ডপলার আল্ট্রাসনোগ্রাফির মাধ্যমে আপনার গর্ভের
                শিশুর হার্টের কার্যকলাপ নির্ণয় করা হয়
              </p>

              <h2 class="text-2xl text-gray-800 font-semibold mb-4 mt-4">
                প্রেগন্যান্সি টেস্ট কিটের ফলাফল কতটা সঠিক?
              </h2>

              <p class="pb-6">
                গর্ভধারণের ছয় দিন পর থেকেই শরীরে ‘বেটা হিউম্যান কোরিওনিক
                গোনাডোট্রপিন’ নামের বিশেষ হরমোন তৈরি হতে শুরু করে। তবে অনেক
                ক্ষেত্রে আট-দশ দিন সময়ও লাগতে পারে। গর্ভাবস্থার শুরুর দিকে শরীরে
                এই হরমোনের পরিমাণ তুলনামূলক কম থাকে। ধীরে ধীরে এই হরমোনের পরিমাণ
                বাড়তে থাকে। তাই অনেকক্ষেত্রেই গর্ভাবস্থার একেবারে শুরুর দিকে
                কিট দিয়ে পরীক্ষা করলে গর্ভবতী নারীদের ক্ষেত্রেও ফলাফল নেগেটিভ
                আসতে পারে।[৪] অনেকেই অনিয়মিত মাসিকের সমস্যায় ভোগেন। ফলে তারা
                মাসিকের সম্ভাব্য তারিখ ঠিকমতো হিসাব করতে পারেন না। সেক্ষেত্রে
                অনেকেই পর্যাপ্ত পরিমাণ হরমোন তৈরি হওয়ার আগেই টেস্ট করে ফেলতে
                পারেন। এমন হলেও গর্ভবতী নারীদের ক্ষেত্রেও টেস্টের ফলাফল নেগেটিভ
                আসতে পারে। এ ছাড়াও প্যাকেটের নির্দেশনা ঠিকমতো না মেনে পরীক্ষা
                করলে গর্ভবতী হলেও ভুলবশত কিট টেস্টের ফলাফল নেগেটিভ আসতে পারে।
                তাই টেস্টের ফলাফল নেগেটিভ আসার পরেও আপনার পিরিয়ড না হলে অথবা
                আপনার নিজেকে গর্ভবতী মনে হলে কয়েকদিন পর আবার প্রেগন্যান্সি টেস্ট
                করতে পারেন। দ্বিতীয়বার টেস্টের ফলাফল নেগেটিভ আসার পরেও যদি আপনার
                পিরিয়ড না হয় তাহলে দ্রুত কোনো গাইনী ডাক্তার অথবা উপজেলা
                স্বাস্থ্য কমপ্লেক্স কিংবা সদর হাসপাতালে যেতে হবে। সেখানে আরও
                কিছু পরীক্ষা-নিরীক্ষা করে আপনি গর্ভবতী কি না সেই বিষয়ে নিশ্চিত
                হতে পারবেন।
              </p>

              <p class="pb-6">
                সর্বপ্রথম জাপানে ১৯৫০ সালের দিকে জাপানে অটো ব্রিউয়ারি সিনড্রোম
                বা এবিএস-এর কথা শোনা যায়। অন্যান্য দেশেও কালক্রমে আবিষ্কৃত হয়
                এই রোগ। এখন পর্যন্ত অন্তত ৫৮ জন রোগীর কথা বিভিন্ন বৈজ্ঞানিক
                জার্নালে পাওয়া গেছে, তবে জাপানীদের মধ্যেই গবেষকেরা এবিএসের
                ঝুঁকি বেশি বলে মনে করেন। বৈজ্ঞানিকদের একটি দল মনে করেন যে তাদের
                মধ্যে জেনেটিক কারণে লিভারে অ্যালকোহলের বিপাক ক্রিয়া বাধাগ্রস্ত
                হয়, যে কারণে রক্তে এর মাত্রা বেড়ে যেতে পারে। মনে রাখতে হবে,
                কেবল নেশাজাতীয় পানীয় নয়, ফার্মেন্টেশনের মাধ্যমে আমাদের শরীরেও
                বিভিন্ন খাদ্য থেকে অ্যালকোহল তৈরি হয়। কিন্তু লিভার সেগুলো ভেঙে
                ফেলে বলে আমরা কিছু টের পাই না।
              </p>
            </div>

            <div class="w-full lg:w-1/4 m-auto mt-12 max-w-screen-sm">
              <div class="p-4 border-t border-b md:border md:rounded">
                <div class="flex py-2">
                  <img
                    src="https://scontent.fdac138-1.fna.fbcdn.net/v/t39.30808-6/345611092_3405322226450369_2387465423623327984_n.jpg?_nc_cat=110&cb=99be929b-3346023f&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeG35K8N6iJb6-YdyTuVuJEceAth3-Uuc_F4C2Hf5S5z8WrXw-8z5LurSWOW_LlNFpSTZBuS4s3G6k7bqB1_QITE&_nc_ohc=3cOWm0S7VOUAX-kAVhG&_nc_ht=scontent.fdac138-1.fna&oh=00_AfDH4h6A825TGb8bcgDa9cL5y_BgKZrQhuc94rA_BqxfPA&oe=64C71829"
                    class="h-10 w-10 rounded-full mr-2 object-cover"
                  />
                  <div>
                    <p class="font-semibold text-gray-700 text-sm">
                      {" "}
                      Mahadi Hasan{" "}
                    </p>
                    <p class="font-semibold text-gray-600 text-xs"> Editor </p>
                  </div>
                </div>
                <p class="text-gray-700 py-3">
                  Mahadi writes about health related blogs
                </p>
                <button class="px-2 py-1 text-gray-100 bg-green-700 flex w-full items-center justify-center rounded">
                  Follow
                  <i class="bx bx-user-plus ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Pregnancy;
